import * as actionTypes from "../constants/action_types";
import { updateObject } from "../utilities";

const initialState = {
  loading: false,
  error: false,
  errorMessage: null,
};

const setIsLoading = (state, action) => {
  console.log(action.payload, "action.payload");
  return updateObject(state, {
    loading: action.payload ?? !state.loading,
  });
};

const setError = (state, action) => {
  return updateObject(state, {
    error: true,
  });
};

const unsetError = (state, action) => {
  return updateObject(state, {
    error: false,
  });
};

const setErrorMessage = (state, action) => {
  return updateObject( state, {
      errorMessage: action.errorMessage
  });
};

const action = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ACTION_SET_IS_LOADING:
      return setIsLoading(state, action);
    case actionTypes.ACTION_SET_ERROR:
      return setError(state, action);
    case actionTypes.PAYMENT_SET_ERROR:
      return setErrorMessage(state, action);
    case actionTypes.ACTION_UNSET_ERROR:
      return unsetError(state, action);
    case actionTypes.RESET:
      return initialState;
    default:
      return state;
  }
};

export default action;
