const publicIp = require('public-ip');

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const updateCheckItems =  (items, newItem, selectedItems = {}, isChecked=true) => {
    if (selectedItems == null || Object.keys(selectedItems).length == 0) selectedItems = {};
    let itemIndex = items.findIndex(currentItem => currentItem.id === newItem.id);
    if (itemIndex != null && itemIndex >= 0) {
        items[itemIndex] = newItem;
    }
    if (isChecked){
        selectedItems[newItem.id] = newItem;
    } else {
        if (newItem.id in selectedItems) delete selectedItems[newItem.id];
    }

    return {items, selectedItems};
};

export const parsePaidPolicy = (relationConcept) => {
    const policy = relationConcept.relation_concept;
    const date = new Date(policy.date);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const dateAsString = day+"/"+month+"/"+year;
    return {
        id: policy.id_relation_concept,
        receipt: policy.invoice.id,
        paymentDate: dateAsString,
        paymentMethod: policy.payment_methods.slice(-1)[0].payment_method.type_payment_method.name,
        value: policy.value,
        paymentState: policy.state,
        isChecked: false };
};

// TODO id_concept_type
// product_id
// punished
//

export const parsePendingPolicy = (relationConcept) => {
    let id = relationConcept.policy_receipt ? relationConcept.policy_receipt : 0;
    if (id == 0 || id == '0'){
        id = relationConcept.policy_number +
             '' + relationConcept.certificate_number +
             '' + relationConcept.document;
    }
    id = parseInt(id);
    let parsedPolicy = {
        id: id,
        product: relationConcept.product.business_line,
        policy_receipt: relationConcept.policy_receipt,
        policy_number: relationConcept.policy_number,
        certificate_number: relationConcept.certificate_number,
        reference_b: relationConcept.reference_b,
        description: relationConcept.reference_b,
        total: relationConcept.total_pending_value,
        is_today_grouped_payment: relationConcept.today_grouped_payment ? true : false,
        origin: relationConcept.system_origin.id,
        concept: {
            portfolio_concept: {
                company_id: relationConcept.company_id.toString(),
                id_concept_type: "Cartera",
                portfolio_type: relationConcept.portfolio_type,
                issue_date: relationConcept.issue_date,
                beginning_date_validity: relationConcept.beginning_date_validity,
                total_value: relationConcept.total_pending_value,
                pending_value: relationConcept.total_pending_value,
                currency_type: relationConcept.currency_id,
                policy_number: relationConcept.policy_number.toString(),
                certificate_number: relationConcept.certificate_number.toString(),
                product: {
                    product_id: relationConcept.product.code.toString(),
                },
                intermediary_key: relationConcept.intermediary_key.toString(),
                policy_receipt: relationConcept.policy_receipt.toString(),
                document: relationConcept.document.toString(),
                punished: false,
                branche_id: relationConcept.branche_id
            }
        },
        isChecked: false
    };
    return parsedPolicy;
};

export const formatPsePayload = () => {
    return {
        request: {
            requestId: "",
            user: "",
            terminal: ""
        },
        launch_payment_pse: {
            client: null,
			payer_info: null, 
			is_remote_payment: false,
            bank: {
                code: "",
                name: ""
            },
            payment_information: {
                payment_method: {
                    type_payment_method: {
                        name: ""
                    },
                    total_value: 0
                },
                concepts: []
            }
        }
    };
};

export const formatGroupedPaymentPayload = () => {
    return {
        request: {
            terminal: null
        },
        client: {
            user_id: null,
            id_identification_type: null,
            id_identification_number: null,
            email: null,
            name: null
        },
        info_payment: {
            total_value: null,
            is_remote_payment: false,
            concepts: []
        }
    }
}



export const formatCreditCardPayload = () => {
    return {
        request: {
            requestId: "",
            user: "",
            terminal: ""
        },
        launch_payment_credit_card: {
            client: {
                user_id: "",
                id_identification_type: "",
                id_identification_number: "",
                name: "",
                email: "",
                intermediary_key: ""
            },
            payment_information: {
                payment_method: {
                    type_payment_method: {
                        name: ""
                    },
                    total_value: 0
                },
                concepts: []
            },
            card_holder: {
                name: "",
                last_name: "",
                telephone_number: "",
                email: "",
                residence_address: "",
                deparment: {
                    id: "",
                    name: ""
                },
                country: {
                    id: "",
                    name: ""
                }
            },
            is_remote_payment: false
        }
    };
};

export const getLocalIp = async () => {
    try {
        let ip = await publicIp.v4();
        console.log('ip address', ip);
        return ip;
    } catch(error) {
        console.log(error);
        return "10.10.0.1";
    }
};

export const buildRequestPendingPolicyReports = (libertyApiUser, documentType, documentNumber, additionalParams) => {
    let requestPayload = {
        request: {
            requestId: Date.now().toString(),
            user: libertyApiUser,
            terminal: sessionStorage.getItem('ip')
        },
        customer: {
            id_identification_type: documentType,
            identification_number: documentNumber.toString()
        },
        portfolio_pending: {
            company_id: "1",
            negative_payment: false,
            is_all_records: true,
            sort: {
                order_by: "FECHA_RECIBO",
                order_type: "ASC"
            },
            filter:{
                product_type: "",
                field_search: ""
            },
            validity:{
                start_date: "",
                end_date: ""
            }
        }        
    };
    if (additionalParams.searchTerm || additionalParams.filterProductType || additionalParams.filterOrder){
        if (additionalParams.searchTerm){
            requestPayload.portfolio_pending.filter.field_search = additionalParams.searchTerm;
        }
        if (additionalParams.filterProductType){
            requestPayload.portfolio_pending.filter.product_type = additionalParams.filterProductType;
        }

        if (additionalParams.filterOrder){
            let order  = additionalParams.filterOrder.split('-');
            requestPayload.portfolio_pending.sort.order_by = order[0];
            requestPayload.portfolio_pending.sort.order_type = order[1];
        }

        if (!!additionalParams.filterCalendar.startDate && !!additionalParams.filterCalendar.endDate){
            requestPayload.portfolio_pending.validity.start_date = additionalParams.filterCalendar.startDate;
            requestPayload.portfolio_pending.validity.end_date = additionalParams.filterCalendar.endDate;
        }

        if (additionalParams.currentReferenceFileId && !additionalParams.isPaginationOnly){
            requestPayload.portfolio_pending.reference_file_id = additionalParams.currentReferenceFileId;
        } else {
            requestPayload.portfolio_pending.file_id = additionalParams.currentReferenceFileId;
        }
    } else {
        if (additionalParams.referenceFileId){
            requestPayload.portfolio_pending.file_id = additionalParams.referenceFileId;
        }
    }
    return requestPayload;
};


/*
module.exports = {
    updateObject,
    updateCheckItems,
    parsePaidPolicy,
    parsePendingPolicy
};
*/

