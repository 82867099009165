import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import App from "./App";
import { Provider } from "react-redux";
import axios from "axios";
import * as serviceWorker from "./serviceWorker";
import store from "./store/store";
import TagManager from "react-gtm-module";

const GOOGLE_TAG_MANAGER_ID = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;

// axios.defaults.baseURL = "https://localhost:3000/";
axios.defaults.headers.common["Content-Type"] = "application/json";
if (process.env.NODE_ENV === "production") {
  // console.log = function () {};
}

const tagManagerArgs = {
  gtmId: GOOGLE_TAG_MANAGER_ID,
};

TagManager.initialize(tagManagerArgs);

window.dataLayer = window.dataLayer || [];

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
