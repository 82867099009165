import axios from 'axios';
import * as actionTypes from '../constants/action_types';
import {getLocalIp} from '../utilities';

const DATA_API = process.env.REACT_APP_BACKEND_API;
const COGNITO_AUTH_API = process.env.REACT_APP_COGNITO_AUTH_API;
const COGNITO_CLIENT_ID = process.env.REACT_APP_COGNITO_AUTH_CLIENT_ID;
const COGNITO_CLIENT_SECRET = process.env.REACT_APP_COGNITO_AUTH_CLIENT_SECRET;
const LIBERTY_API_USER = process.env.REACT_APP_LIBERTY_API_USER;

let getIdTypes = () => {
    return new Promise ((resolve, reject) => {
        const requestPayload = {
            request: {
                requestId: Date.now().toString(),
                terminal: '100.64.1.190',
                user: LIBERTY_API_USER
            }
        };
        console.log('request id types', requestPayload);

        const requestConfig = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        };
        axios.post(DATA_API + "/generic-params/type-identification/index", requestPayload, requestConfig).then(response => {
            console.log(response.data);
            if (response && response.data){
                resolve(response.data.data.types_of_identification);
            }
        }).catch(error => {
            console.log('error identification type', error);
            reject(null);
        });
    });
};

let getConfigParams = () => {
    return new Promise ((resolve, reject) => {
        const requestPayload = {
            request: {
                requestId: Date.now().toString(),
                terminal: '100.64.1.190',
                user: LIBERTY_API_USER
            }
        };
        const requestConfig = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        };
        axios.post(DATA_API + "/generic-params/general-param/index", requestPayload, requestConfig).then(response => {
            if (response && response.data){
                console.log('generic parameters', response.data);
                sessionStorage.setItem('company_id', response.data.data.company_id);
                resolve(response.data.data);
            } else {
                reject(null);
            }
        }).catch(error => {
            console.log('error generic param', error);
            reject(null);
        });

    });
};


export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authSuccess = (id, idType, userId, email, intermediaryKey, name, role='Client') => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        id: id,
        name: name,
        idType: idType,
        userId: userId,
        email: email,
        intermediaryKey: intermediaryKey,
        role:role
    };
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    };
};

export const isLoading = () => {
    return {
        type: actionTypes.ACTION_SET_IS_LOADING
    };
};

export const setError = () => {
    return {
        type: actionTypes.ACTION_SET_ERROR
    };
};

export const setIsRemotePayment = () => {
    return {
        type: actionTypes.SET_IS_REMOTE_PAYMENT
    };
};

export const setConfigParams = (params) => {
    return {
        type: actionTypes.AUTH_SET_CONFIG_PARAMS,
        params
    };
};

export const setIdTypes = (idTypes) => {
    return {
        type: actionTypes.AUTH_SET_ID_TYPES,
        idTypes
    };
};

export const logoutAction = () => {
    return (dispatch) => {
        dispatch(reset());
        dispatch(logout());
    };
};

export const reset = () => {
    sessionStorage.clear();
    return {
        type: actionTypes.RESET
    };
};

export const logout = () => {
    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const portalAuthenticated = (token) => {
    return {
        type: actionTypes.PORTAL_AUTHENTICATED,
        token
    };
};

export const setAdminAuthCredentials = (token) => {
    sessionStorage.setItem('admin_token', token);
    return {
        type: actionTypes.PORTAL_ADMIN_AUTHENTICATED,
        token
    };
};

export const requestAccessPortal = () => {
    return dispatch => {
        let inputString = `${COGNITO_CLIENT_ID}:${COGNITO_CLIENT_SECRET}`;
        let buffer = new Buffer(inputString);
        let base64Auth = buffer.toString("base64");
        const authRequestConfig = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Basic ${base64Auth}`,
            }
        };
        const requestBody = 'grant_type=client_credentials';
        console.log(COGNITO_AUTH_API);
        axios.post(COGNITO_AUTH_API, requestBody, authRequestConfig).then((response) => {
            let token = response.data.access_token;
            sessionStorage.setItem('token', token);
            dispatch(portalAuthenticated(token));
            dispatch(fetchConfigurationParams());
            // dispatch(fetchInitialParams('1234', '1234'));
            // dispatch(fetchIdTypes('1234', '1234'));
        }).catch((error) => {
            console.log('error requesting token');
            dispatch(setError());
        });
    };
};


export const auth = (documentType, documentNumber) => {
    return dispatch => {
        dispatch(authStart());
        const authData = {
            document_type: documentType,
            document_number: documentNumber
        };
        const requestPayload = {
            request:{
                applicationClient: "PORTAL PAGOS",
                user: LIBERTY_API_USER,
                date: Date.now(),
                requestId: Date.now().toString(),
                terminal: '100.64.1.190' 
            },
            client: {
                idIdentificationType: documentType,
                identificationNumber: authData.document_number.toString()
            }
        };

        const requestConfig = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        };

        dispatch(isLoading());
        axios.post(DATA_API + "/users/client/exist"  , requestPayload, requestConfig).then(response => {
            if (response && response.data){
                let clientData = response.data.data;
                if (clientData.clientExists){
                    let idType = response.data.data.client.id_identification_type;
                    let id = response.data.data.client.identification_number;
                    let userId = response.data.data.client.user_id;
                    let email = response.data.data.client.email;
                    let intermediaryKey = response.data.data.client.intermediary_key;
                    let name = response.data.data.client.name;
                    let role = 'Client';
                    sessionStorage.setItem('idType', idType);
                    sessionStorage.setItem('id', id);
                    sessionStorage.setItem('userId', userId);
                    sessionStorage.setItem('email', email);
                    sessionStorage.setItem('intermediaryKey', intermediaryKey);
                    sessionStorage.setItem('name', name);
                    sessionStorage.setItem('role', role);
                    dispatch(authSuccess(id, idType, userId, email, intermediaryKey, name,role));
                }  else {
                    dispatch(authFail('could not init session'));
                }
            } else {
                dispatch(authFail('could not init session'));
            }
        }).catch(error => {
            console.log(error);
            dispatch(authFail(error));
            dispatch(setError());
        }).finally(() => {
            dispatch(isLoading());
        });
    };
};

export const fetchInitialParams =  () => {
    console.log(DATA_API);
    return dispatch => {
        const requestPayload = {
            request: {
                requestId: Date.now().toString(),
                terminal: '100.64.1.190',
                user: LIBERTY_API_USER
            }
        };

        const requestConfig = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        };
        dispatch(isLoading());
        axios.post(DATA_API + "/generic-params/general-param/index", requestPayload, requestConfig).then(response => {
            if (response && response.data){
                console.log('generic parameters', response.data);
                sessionStorage.setItem('company_id', response.data.data.company_id);
                dispatch(setConfigParams(response.data.data));
            } else {
                dispatch(authFail('could not obtain configuration params'));
            }
        }).catch(error => {
            console.log(error);
            dispatch(setError());
            dispatch(authFail(error));
        }).finally(() => {
            dispatch(isLoading());
        });
    };
};

export const fetchIdTypes = (requestId, terminal) => {
    return dispatch => {
        const requestPayload = {
            request: {
                requestId: Date.now().toString(),
                terminal: '100.64.1.190',
                user: LIBERTY_API_USER
            }
        };

        const requestConfig = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        };
        dispatch(isLoading());
        axios.post(DATA_API + "/generic-params/type-identification/index", requestPayload, requestConfig).then(response => {
            console.log(response.data);
            if (response && response.data){
                dispatch(setIdTypes(response.data.data.types_of_identification));
            }
        }).catch(error => {
            dispatch(setError());
        }).finally(() => {
            dispatch(isLoading());
        });
    };
};

export const fetchConfigurationParams = () => {
    let fetchIdTypes = getIdTypes();
    let fetchConfigParams = getConfigParams();
    return dispatch => {
        dispatch(isLoading());
        Promise.all([fetchIdTypes, fetchConfigParams]).then((configParams) => {
            console.log('config', configParams);
            dispatch(setIdTypes(configParams[0]));
            dispatch(setConfigParams(configParams[1]));
        }).catch((error) => {
            console.log(error);
            dispatch(setError());
        }).finally(() => {
            dispatch(isLoading());
        });
    };
};

export const authCheckState = () => {
    console.log('check state');
    return (dispatch) => {
        const token = sessionStorage.getItem('token');
        const idType = sessionStorage.getItem('idType');
        const id = sessionStorage.getItem('id');
        const userId = sessionStorage.getItem('userId');
        const email = sessionStorage.getItem('email');
        const intermediaryKey = sessionStorage.getItem('intermediaryKey');
        const name = sessionStorage.getItem('name');
        const role = sessionStorage.getItem('role');
        if (!token || !id || !idType) {
            dispatch(logout());
            dispatch(reset());
        } else {
            dispatch(authSuccess(id, idType, userId, email, intermediaryKey, name,role));
        }
    };
};

