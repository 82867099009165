import * as actionTypes from '../constants/action_types';
import {updateObject} from '../utilities';

const initialState = {
    countries: [],
    departments: [],
    paymentParameters: {}
}

const setCountries = (state, action) => {
    const countries = action.countries;
    return updateObject(state, {
        countries: countries
    });
}

const setDepartments = (state, action) => {
    const departments = action.departments;
    return updateObject(state, {
        departments: departments
    });
}

const setPaymentParameters = (state, action) => {
    const paymentParameters = action.paymentParameters;
    return updateObject(state, {
        paymentParameters: paymentParameters
    });
}

const genericData = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GENERIC_DATA_SET_COUNTRIES: return setCountries(state, action);
        case actionTypes.GENERIC_DATA_SET_DEPARTMENTS: return setDepartments(state, action);
        case actionTypes.GENERIC_DATA_SET_PAYMENT_PARAMETERS: return setPaymentParameters(state, action);
        case actionTypes.RESET:
            return initialState;
      default:
          return state;
    }
}

export default genericData;
