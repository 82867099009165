import * as actionTypes from '../constants/action_types';
import {updateObject} from '../utilities';

const initialState = {
    paymentHtml: "",
    creditCardRelationConcept: null,
    paymentStatusData: null,
    isBlockedPaymentButton: false,
    confirmationEmailSent: false,
    didPaymentSucceed: false
};

const setPaymentHtml = (state, action) => {
    const paymentHtml = action.paymentHtml;
    return updateObject(state, {
        paymentHtml: paymentHtml
    });
};

const setPaymentRelationConcept = (state, action) => {
    const relationConcept = action.relationConcept;
    return updateObject(state, {
        creditCardRelationConcept: relationConcept
    });
};

const setIsBlockedPaymentButton = (state, action) => {
    const isBlockedPaymentButton = action.isBlockedPaymentButton;
    return updateObject(state, {
        isBlockedPaymentButton: isBlockedPaymentButton
    });
};


const setPaymentStatusData = (state, action) => {
    const paymentStatusData = action.paymentStatusData;
        return updateObject(state, {
            paymentStatusData: paymentStatusData
        });
};

const setConfirmationEmailSent = (state, action) => {
    let confirmationEmailSent = state.confirmationEmailSent;
        return updateObject(state, {
            confirmationEmailSent: !confirmationEmailSent
        });
};

const setDidPaymentSucceed = (state, action) => {
    let didPaymentSucceed = action.didPaymentSucceed;
    return updateObject(state, {
        didPaymentSucceed: didPaymentSucceed
    });
};

const creditCardPayment = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_PAYMENT_HTML: return setPaymentHtml(state, action);
        case actionTypes.SET_CREDIT_CARD_PAYMENT_STATUS_DATA: return setPaymentStatusData(state, action);
        case actionTypes.SET_PAYMENT_IS_BLOCKED_PAYMENT_BUTTON: return setIsBlockedPaymentButton(state, action);
        case actionTypes.CONFIRMATION_EMAIL_SENT_CREDIT_CARD: return setConfirmationEmailSent(state, action);
        case actionTypes.SET_CREDIT_CARD_PAYMENT_RELATION_CONCEPT: return setPaymentRelationConcept(state, action);
        case actionTypes.SET_CREDIT_CARD_DID_PAYMENT_SUCCEED: return setDidPaymentSucceed(state, action);
        case actionTypes.RESET:
            return initialState;
      default:
          return state;
    }
};

export default creditCardPayment;
