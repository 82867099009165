import React, { Component, Suspense } from 'react';
import { Redirect,  HashRouter, BrowserRouter, Router, Route, Switch, withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from './store/actions/auth';
import logo from './logo.svg';
import Loading from './views/Pages/Loading.js';
import {getLocalIp} from './store/utilities';
import 'babel-polyfill';
import Amplify from 'aws-amplify';

Amplify.configure({
  Auth: {
      region: process.env.REACT_APP_ADMIN_AUTH_REGION,
      userPoolId: process.env.REACT_APP_ADMIN_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_ADMIN_USER_POOL_WEB_CLIENT,
      oauth: {
          redirectSignIn: process.env.REACT_APP_ADMIN_OAUTH_REDIRECT_SIGN_IN,
          redirectSignOut: process.env.REACT_APP_ADMIN_OAUTH_REDIRECT_SIGN_OUT,
          domain: process.env.REACT_APP_ADMIN_OAUTH_DOMAIN,
          scope: ["openid", "email", "profile"],
          responseType: 'code'
      }
  }
});
// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout/DefaultLayout'));
const AuthLayout = React.lazy(() => import('./containers/DefaultLayout/AuthLayout'));

class App extends Component {

  loading = () => <div className="animated fadeIn pt-1 text-center"><Loading/></div>

  constructor(props) {
    super(props);
    this.state = {
      logout: false
    }
  }

  async componentDidMount(){
      try {
        let ip = await getLocalIp();
        console.log('local ip', ip);
        sessionStorage.setItem("ip", ip);
        this.props.requestAccessPortal();
        this.props.authCheckState();
      } catch(error){
        console.log(error);
        sessionStorage.setItem("ip", "10.10.0.1");
      }
  }

  async componentDidUpdate(prevProps, prevState) {
    try {
        let ip = await getLocalIp();
        sessionStorage.setItem("ip", ip);
    } catch(error){
        console.log(error);
        sessionStorage.setItem("ip", "10.10.0.1");
    }

    if (!sessionStorage.getItem("ip")) {
      getLocalIp().then(ip => {
        sessionStorage.setItem("ip", ip);
      });
    }

    if (!this.props.token && prevProps.token) {
        this.props.requestAccessPortal();
    }

    if (this.props.logout && !prevProps.logout){
        this.setState({...this.state, logout: true});
    }

  };

  render() {
      console.log('is remote payment', this.props.isRemotePayment);

      let nonAuthroutes = (
            <Switch>
              <Route path="/" name="Home" render={props => <DefaultLayout {...props}/>} />
            </Switch>
      );

      let authRoutes = (
            <Switch>
                <Route path="/" name="Home" render={props => <AuthLayout {...props}/>} />
            </Switch>
      );

      return (
        <div>
          <React.Suspense fallback={this.loading()}>
              <BrowserRouter>
                {
                    (this.props.id && this.props.idType && !this.props.logout) ?
                    authRoutes : nonAuthroutes
                }
                {
                    (this.props.logout && (!window.location.href.includes('paymentstatus') &&
                    !window.location.href.includes('payment') &&
                    !window.location.href.includes('admin'))) ?
                    <Redirect to="/"/> :
                    (this.props.logout && window.location.href.includes('admin/parameters')) ?
                    <Redirect to="/"/> :  null

                }
            </BrowserRouter>
          </React.Suspense>
        </div>
      );
  }
}

const mapStateToProps = state => {
    return {
        token: state.session.token,
        idType: state.session.idType,
        id: state.session.id,
        logout: state.session.logout,
        isRemotePayment: state.session.isRemotePayment,
        role: state.session.role,
    };
};


const mapDispatchToProps = dispatch => {
  return {
    requestAccessPortal: () => dispatch(actions.requestAccessPortal()),
    authCheckState: () => dispatch(actions.authCheckState())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
