import * as actionTypes from '../constants/action_types';
import { updateObject, updateCheckItems } from '../utilities';

const initialState = {
    operation: '',
    groupedPaymentsToday: null, 
    selectedItems: null,
    recordsReport: null,
    searchTerm: '',
    perPage: 5,
    activePage: 1,
    totalItems: 10,
    items: [],
    emptyFilter: true,
    total: 0,
    numberItems: 0,
    totalValuePortfolio: 0,
    referenceFileId: null,
    currentReferenceFileId: null,
    reportLink: null,
    reportSent: false,
    filter: {
        filterProductType: '',
        filterOrder: 'FECHA_RECIBO-ASC'
    }
};

const itemChecked = (state, action) => {
    let checkedItem = action.checkedItem;
    checkedItem.isChecked = true;
    let selectedItems = state.selectedItems;
    let items = state.items;
    // new values 
    let newValues  = updateCheckItems(items, checkedItem, selectedItems, true);
    selectedItems = {...newValues.selectedItems};
    let total = 0;
    let selectedItemsEntries = Object.entries(selectedItems);
    let numberItems = selectedItemsEntries.length;
    for (const [key, value] of selectedItemsEntries) {
        total += value.total;
    }
    return updateObject(state, {
                        operation: 'checked',
                        selectedItems: {...newValues.selectedItems},
                        items: [...newValues.items],
                        numberItems: numberItems, 
                        total: total
    });
};

const itemUnchecked = (state, action) => {
    let uncheckedItem = action.uncheckedItem;
    uncheckedItem.isChecked = false;
    let selectedItems = state.selectedItems;
    let items = state.items;
    // new values 
    let newValues  = updateCheckItems(items, uncheckedItem, selectedItems, false);
    selectedItems = {...newValues.selectedItems};
    let total = 0;
    let selectedItemsEntries = Object.entries(selectedItems);
    let numberItems = selectedItemsEntries.length;
    for (const [key, value] of selectedItemsEntries) {
        total += value.total;
    }
    return updateObject(state, {
                        operation: 'unchecked',
                        selectedItems: {...newValues.selectedItems},
                        items: [...newValues.items],
                        numberItems: numberItems, 
                        total: total
    });
};

const updateItemsStatus = (state, action) => {
    let items = state.items;
    let selectedItems = state.selectedItems;
    if (selectedItems){
        items = items.map((item) => {
            if(item.id in selectedItems) item.isChecked = true;
            return item;
        });
    }
    return updateObject(state, {items: [...items]});
};

const setItems = (state, action) => {
    let items = action.items;
    return updateObject(state, {items: items});
};

const setGroupedPaymentsToday = (state, action) => {
  let groupedPaymentsToday = action.groupedPaymentsToday; 
  return updateObject(state, {groupedPaymentsToday: groupedPaymentsToday}); 
}; 

const setTotal = (state, action) => {
    let total = action.total;
    return updateObject(state, {total: total});

};

const setTotalValuePortfolio = (state, action) => {
    let totalValuePortfolio = action.totalValuePortfolio;
    return updateObject(state, {totalValuePortfolio});
};

const setNumberItems = (state, action) => {
    let numberItems = action.numberItems;
    return updateObject(state, {numberItems: numberItems});
};

const setPerPage = (state, action) => {
    let perPage = action.perPage;
    return updateObject(state, {perPage: perPage});
};

const setActivePage = (state, action) => {
    let activePage = action.activePage;
    return updateObject(state, {activePage: activePage});

};

const setIsEmptyFilters =  (state, action) => {
    return updateObject(state, {emptyFilter: true});
};

const setIsNotEmptyFilters =  (state, action) => {
    return updateObject(state, {emptyFilter: false});
};

const setSearchTerm = (state, action) => {
    let searchTerm = action.searchTerm;
    return updateObject(state, {searchTerm: searchTerm});
};

const setTotalItems = (state, action) => {
    let totalItems = action.totalItems;
    return updateObject(state, {totalItems: totalItems});
};

const setValues = (state, action) => {
    let selectedItems = state.selectedItems;
    let total = 0;
    let selectedItemsEntries = Object.entries(selectedItems);
    let numberItems = selectedItemsEntries.length;
    for (const [key, value] of selectedItemsEntries) {
        total += value.total;
    }
    return updateObject(state, {numberItems: numberItems, total: total});
};

const setInitialReferenceFileId = (state, action) => {
    let referenceFileId = action.referenceFileId;
    return updateObject(state, {referenceFileId});
};

const setCurrentReferenceFileId = (state, action) => {
    let currentReferenceFileId = action.referenceFileId;
    return updateObject(state, {currentReferenceFileId});
};

const setRecordsReport = (state, action) => {
    let recordsReport = action.recordsReport;
    return updateObject(state, {recordsReport});
};


const setReferenceFileId = (state, action) => {
    let currentReferenceFileId = state.referenceFileId;
    let referenceFileId = action.referenceFileId;
    if (!currentReferenceFileId){
        return updateObject(state, {referenceFileId: referenceFileId});
    }
    return updateObject(state, {currentReferenceFileId: referenceFileId});
};

const emptySelectedPolicies = (state, action) => {
    return updateObject(state, {selectedItems: {}});
};

const resetItems = (state, action) => {
    return updateObject(state, initialState);
};

const setFilterParams = (state, action) => {
    return updateObject(state, { filter: action.filter});
};

const setReportLink = (state, action) => {
    return updateObject(state, { reportLink: action.reportLink});
};

const setReportSent = (state, action) => {
    return updateObject(state, { reportSent: action.reportSent});
};

const paymentSummary = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PENDING_POLICIES_ITEM_CHECK: return itemChecked(state, action);
    case actionTypes.PENDING_POLICIES_ITEM_UNCHECK: return itemUnchecked(state, action);
    case actionTypes.PENDING_POLICIES_ITEMS: return setItems(state, action);
    case actionTypes.PENDING_POLICIES_SET_TOTAL: return setTotal(state, action);
    case actionTypes.PENDING_POLICIES_SET_NUMBER_ITEMS: return setNumberItems(state, action);
    case actionTypes.PENDING_POLICIES_SET_VALUES: return setValues(state, action);
    case actionTypes.PENDING_POLICIES_UPDATE_ITEMS_STATUS: return updateItemsStatus(state, action);
    case actionTypes.PENDING_POLICIES_SET_PER_PAGE: return setPerPage(state, action);
    case actionTypes.PENDING_POLICIES_SET_ACTIVE_PAGE: return setActivePage(state, action);
    case actionTypes.PENDING_POLICIES_SET_TOTAL_ITEMS: return setTotalItems(state, action);
    case actionTypes.PENDING_POLICIES_SET_SEARCH_TERM: return setSearchTerm(state, action);
    case actionTypes.PENDING_POLICIES_SET_TOTAL_VALUE_PORTFOLIO: return setTotalValuePortfolio(state, action);
    case actionTypes.PENDING_POLICIES_SET_REFERENCE_FILE_ID: return setReferenceFileId(state, action);
    case actionTypes.PENDING_POLICIES_SET_IS_EMPTY_FILTER: return setIsEmptyFilters(state, action);
    case actionTypes.PENDING_POLICIES_SET_IS_NOT_EMPTY_FILTER: return setIsNotEmptyFilters(state, action);
    case actionTypes.RESET: return resetItems(state, action);
    case actionTypes.RESET_SELECTED_POLICIES: return emptySelectedPolicies(state, action);
    case actionTypes.PENDING_POLICIES_SET_FILTER_PARAMS: return setFilterParams(state, action);
    case actionTypes.SET_GROUPED_PAYMENTS_FOR_TODAY: return setGroupedPaymentsToday(state, action); 
    case actionTypes.SET_RECORDS_REPORTS: return setRecordsReport(state, action);  
    case actionTypes.SET_REPORT_LINK: return setReportLink(state, action); 
    case actionTypes.SET_REPORT_SENT: return setReportSent(state, action); 
    default:
        return state;
  }
};


export default paymentSummary;
