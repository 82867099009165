import { combineReducers } from 'redux';
import session from './session';
import action from './action';
import pendingPolicies from './pending_policies';
import paidPolicies from './paid_policies';
import payment from './payment';
import genericData from './generic_data';
import psePayment from './pse_payment';
import creditCardPayment from './credit_card_payment';
import groupedPayment from './grouped_payment';
import admin from './admin'

const appReducers = combineReducers({
  session,
  pendingPolicies,
  paidPolicies,
  action,
  payment,
  genericData,
  psePayment,
  creditCardPayment,
  groupedPayment, 
  admin
});

const rootReducer = (state, action) => {
    let reducers = appReducers(state, action);
    return reducers;
};

export default rootReducer;
