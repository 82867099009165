import * as actionTypes from "../constants/action_types";
import { updateObject } from "../utilities";

const initialState = {
  loading: false,
  error: false,
  parameters: [],
  message: {
    isError: false,
    isSuccess: false,
    text: null,
  },
};

const setParameters = (state, action) => {
  return updateObject(state, {
    parameters: action.parameters,
  });
};

const setMessage = (state, action) => {
  return updateObject(state, {
    message: action.message,
  });
};

const setIsLoading = (state, action) => {
  console.log(action.payload, "action.payload");
  return updateObject(state, {
    loading: action.payload ?? !state.loading,
  });
};

const setError = (state, action) => {
  return updateObject(state, {
    error: true,
  });
};

const unsetError = (state, action) => {
  return updateObject(state, {
    error: false,
  });
};

const admin = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ACTION_SET_IS_LOADING:
      return setIsLoading(state, action);
    case actionTypes.ACTION_SET_ERROR:
      return setError(state, action);
    case actionTypes.ACTION_UNSET_ERROR:
      return unsetError(state, action);
    case actionTypes.SET_PARAMETERS:
      return setParameters(state, action);
    case actionTypes.SET_MESSAGE:
      return setMessage(state, action);
    case actionTypes.RESET:
      return initialState;
    default:
      return state;
  }
};

export default admin;
