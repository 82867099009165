import * as actionTypes from '../constants/action_types';
import {updateObject} from '../utilities';

const initialState = {
    selectedMethod: '',
    isFinanced: false,
    paymentStep: '',
    invoiceLink: '',
    externalOrigin: '',
    notificationData: null,
    email: null
};

const setNotificationData = (state, action) => {
    const notificationData = action.notificationData;
    return updateObject(state, {
        notificationData: notificationData
    });
};

const setPaymentMethod = (state, action) => {
    const selectedMethod = action.selectedMethod;
    const isFinanced = action.isFinanced;
    return updateObject(state, {
        selectedMethod: selectedMethod,
        isFinanced: isFinanced
    });
};

const setPaymentStep = (state, action) => {
    const paymentStep = action.paymentStep;
    return updateObject(state, {
        paymentStep: paymentStep
    });
};

const setPaymentEmail = (state, action) => {
    const email = action.email;
    return updateObject(state, {
        email: email
    });
};


const setInvoiceLink = (state, action) => {
    const invoiceLink = action.invoiceLink;
    return updateObject(state, {
        invoiceLink: invoiceLink
    });
};

const setExternalPaymentOrigin = (state, action) => {
    const externalOrigin = action.externalOrigin;
    return updateObject(state, {
        externalOrigin: externalOrigin
    });
};

const payment = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_PAYMENT_METHOD: return setPaymentMethod(state, action);
        case actionTypes.SET_PAYMENT_STEP: return setPaymentStep(state, action);
        case actionTypes.RESET: return initialState;
        case actionTypes.SET_INVOICE_LINK: return setInvoiceLink(state, action);
        case actionTypes.SET_EXTERNAL_PAYMENT_ORIGIN: return setExternalPaymentOrigin(state, action);
        case actionTypes.SET_EMAIL_RECEIPT : return setPaymentEmail(state,action);
        case actionTypes.SET_EXTERNAL_PAYMENT_NOTIFICATION_DATA: return setNotificationData(state, action);
        default: return state;
    }
};

export default payment;
