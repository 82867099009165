import * as actionTypes from '../constants/action_types';
import {updateObject} from '../utilities';

const initialState = {
    logout: true,
    token: null,
    adminToken: null,
    id: null,
    idType: null,
    email: null,
    role:null,
    name: null,
    intermediary_key: null,
    user_id: null,
    idTypes: [],
    company_id: null,
    allow_referenced_payment: null,
    virtual_user_collector: null,
    name_type_of_payment_pse: null,
    name_type_of_card_payment: null,
    name_type_of_payment_financial_entity: null,
    error: null,
    isRemotePayment: false,
    hasUncompletedPayments: false,
};

const authStart = ( state, action ) => {
    return updateObject( state, {
        error: null
    });
};

const portalAuthenticated = (state, action) => {
    return updateObject( state, {
        token: action.token
     });
};

const setClientEmail = (state, action) => {
    return updateObject( state, {
        email: action.email
     });
};

const authSuccess = (state, action) => {
    return updateObject( state, {
        id: action.id,
        idType: action.idType,
        user_id: action.userId,
        email: action.email,
        intermediary_key: action.intermediaryKey,
        name: action.name,
        logout: false,
        error: null,
        role: action.role
     });
};

const authFail = (state, action) => {
    return updateObject( state, {
        error: action.error
    });
};


const setIdTypes = (state, action) => {
    return updateObject( state, {
        idTypes: action.idTypes
    });
};

const setConfigParams = (state, action) => {
    console.log('action before config params', action);
    return updateObject(state, {
        company_id: action.params.company_id,
        allow_referenced_payment: action.params.allow_referenced_payment,
        virtual_user_collector: action.params.virtual_user_collector,
        name_type_of_payment_pse: action.params.name_type_of_payment_pse,
        name_type_of_card_payment: action.params.name_type_of_card_payment,
        name_type_of_payment_financial_entity: action.params.name_type_of_payment_financial_entity
    });
};

const setLogout = (state, action) => {
    return updateObject(state, {
        logout: true
    });
};

const setIsRemotePayment = (state, action) => {
    return updateObject(state, {
        isRemotePayment: true
    });
};

const setHasUncompletedPayments = (state, action) => {
    return updateObject( state, {
        hasUncompletedPayments: action.status
     });
};

const portalAdminAuthenticated = (state, action) => {
    return updateObject( state, {
        adminToken: action.token
     });
}

const session = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START: return authStart(state, action);
    case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
    case actionTypes.AUTH_FAIL: return authFail(state, action);
    case actionTypes.AUTH_SET_CONFIG_PARAMS: return setConfigParams(state, action);
    case actionTypes.AUTH_SET_ID_TYPES: return setIdTypes(state, action);
    case actionTypes.PORTAL_AUTHENTICATED: return portalAuthenticated(state, action);
    case actionTypes.AUTH_LOGOUT: return setLogout(state, action);
    case actionTypes.SET_IS_REMOTE_PAYMENT: return setIsRemotePayment(state, action);
    case actionTypes.SET_USER_HAS_UNCOMPLETED_PAYMENTS: return setHasUncompletedPayments(state, action);
    case actionTypes.SET_CLIENT_EMAIL: return setClientEmail(state, action);
    case actionTypes.PORTAL_ADMIN_AUTHENTICATED: return portalAdminAuthenticated(state, action);
    case actionTypes.RESET:
        return initialState;
    default:
        return state;
  }
};


export default session;
