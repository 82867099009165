import React from 'react';
import LoadingImg from '../../assets/images/loader-a.svg';
import './Loading.scss';

const Loading = (props) => {
    return (
        <div className="loading-container">
            <img alt="" src={LoadingImg} className="d-inline-block"/>
        </div>
    );
}

export default Loading;
