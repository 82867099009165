import * as actionTypes from '../constants/action_types';
import {updateObject} from '../utilities';

const initialState = {
    banks: [],
    paymentUrl: null,
    pseRelationConcept: null,
    isBlockedUrl: false,
    paymentStatusData: null,
    confirmationEmailSent: false,
    didPaymentSucceed: false
};

const setBanksList = (state, action) => {
    const banks = action.banks;
    return updateObject(state, {
        banks: banks
    });
};

const setPaymentUrl = (state, action) => {
    const paymentUrl = action.paymentUrl;
    return updateObject(state, {
        paymentUrl: paymentUrl
    });
};

const setIsBlockedUrl = (state, action) => {
    const isBlockedUrl = action.isBlocked;
    return updateObject(state, {
        isBlockedUrl: isBlockedUrl
    });
};

const setPaymentRelationConcept = (state, action) => {
    const relationConcept = action.relationConcept;
    return updateObject(state, {
        pseRelationConcept: relationConcept
    });
};

const setPaymentStatusData = (state, action) => {
    const paymentStatusData = action.paymentStatusData;
        return updateObject(state, {
            paymentStatusData: paymentStatusData
        });
};

const setConfirmationEmailSent = (state, action) => {
    let confirmationEmailSent = state.confirmationEmailSent;
        return updateObject(state, {
            confirmationEmailSent: !confirmationEmailSent
        });
};

const setDidPaymentSucceed = (state, action) => {
    let didPaymentSucceed = action.didPaymentSucceed;
    return updateObject(state, {
        didPaymentSucceed: didPaymentSucceed
    });
};

const psePayment = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_BANKS_LIST: return setBanksList(state, action);
        case actionTypes.SET_PAYMENT_URL: return setPaymentUrl(state, action);
        case actionTypes.SET_PSE_PAYMENT_STATUS_DATA: return setPaymentStatusData(state, action);
        case actionTypes.SET_PAYMENT_IS_BLOCKED_URL: return setIsBlockedUrl(state, action);
        case actionTypes.SET_PSE_PAYMENT_RELATION_CONCEPT: return setPaymentRelationConcept(state, action);
        case actionTypes.CONFIRMATION_EMAIL_SENT_PSE: return setConfirmationEmailSent(state, action);
        case actionTypes.SET_PSE_DID_PAYMENT_SUCCEED: return setDidPaymentSucceed(state, action);
        case actionTypes.RESET:
            return initialState;
        default:
            return state;
    }
};


export default psePayment;
