import * as actionTypes from '../constants/action_types';
import { updateObject, updateCheckItems } from '../utilities';

const initialState = {
    operation: '',
    selectedPolicies: null,
    policies: [],
    searchTerm: '',
    emptyFilter: true,
    perPage: 5,
    activePage: 1,
    totalItems: 10,
    numberPolicies: 0,
    filter: {
        filterOrder: 'FECHA-ASC'
    }
};

const policyChecked = (state, action) => {
    let checkedPolicy = action.checkedPolicy;
    checkedPolicy.isChecked = true;
    let selectedPolicies = state.selectedPolicies;
    let policies = state.policies;
    let newValues = updateCheckItems(policies, checkedPolicy, selectedPolicies, true);
    // selectedPolicies[action.checkedPolicy.id]  = action.checkedPolicy;
    return updateObject(state, {operation: 'checked',
                        selectedPolicies: {...newValues.selectedItems},
                        policies: [...newValues.items]});
};

const policyUnchecked = (state, action) => {
    let uncheckedPolicy = action.uncheckedPolicy;
    uncheckedPolicy.isChecked = false;
    let selectedPolicies = state.selectedPolicies;
    let policies = state.policies;
    let newValues  = updateCheckItems(policies, uncheckedPolicy, selectedPolicies, false);
    return updateObject(state, {operation: 'unchecked',
                        selectedPolicies: {...newValues.selectedItems},
                        policies: [...newValues.items]});
};

const updatePoliciesStatus = (state, action) => {
    let policies = state.policies;
    let selectedPolicies = state.selectedPolicies;
    if (selectedPolicies){
        policies = policies.map((policy) => {
            if(policy.id in selectedPolicies) policy.isChecked = true;
            return policy;
        });
    }
    return updateObject(state, {policies: [...policies]});
};

const setPolicies = (state, action) => {
    let policies = action.policies;
    return updateObject(state, {policies: policies});
};

const setNumberPolicies = (state, action) => {
    let numberPolicies = action.numberPolicies;
    return updateObject(state, {numberPolicie: numberPolicies});
};

const setValues = (state, action) => {
    let selectedPolicies = state.selectedPolicies;
    let selectedPoliciesEntries = Object.entries(selectedPolicies);
    let numberPolicies = selectedPoliciesEntries.length;
    return updateObject(state, {numberPolicies: numberPolicies});
};

const setPerPage = (state, action) => {
    let perPage = action.perPage;
    return updateObject(state, {perPage: perPage});
};

const setActivePage = (state, action) => {
    let activePage = action.activePage;
    return updateObject(state, {activePage: activePage});

};

const setSearchTerm = (state, action) => {
    let searchTerm = action.searchTerm;
    return updateObject(state, {searchTerm: searchTerm});
};

const setTotalItems = (state, action) => {
    let totalItems = action.totalItems;
    return updateObject(state, {totalItems: totalItems});
};

const resetItems = (state, action) => {
    return updateObject(state, initialState);
};

const setFilterParams = (state, action) => {
    return updateObject(state, { filter: action.filter});
};

const setIsEmptyFilters =  (state, action) => {
    return updateObject(state, {emptyFilter: true});
};

const setIsNotEmptyFilters =  (state, action) => {
    return updateObject(state, {emptyFilter: false});
};

const downloadSummary = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DOWNLOAD_SUMMARY_POLICY_CHECK: return policyChecked(state, action);
    case actionTypes.DOWNLOAD_SUMMARY_POLICY_UNCHECK: return policyUnchecked(state, action);
    case actionTypes.DOWNLOAD_SUMMARY_POLICIES: return setPolicies(state, action);
    case actionTypes.DOWNLOAD_SUMMARY_SET_NUMBER_POLICIES: return setNumberPolicies(state, action);
    case actionTypes.DOWNLOAD_SUMMARY_SET_VALUES: return setValues(state, action);
    case actionTypes.DOWNLOAD_SUMMARY_UPDATE_POLICIES_STATUS: return updatePoliciesStatus(state, action);
    case actionTypes.PAID_POLICIES_SET_PER_PAGE: return setPerPage(state, action);
    case actionTypes.PAID_POLICIES_SET_ACTIVE_PAGE: return setActivePage(state, action);
    case actionTypes.PAID_POLICIES_SET_SEARCH_TERM: return setSearchTerm(state, action);
    case actionTypes.PAID_POLICIES_SET_TOTAL_ITEMS: return setTotalItems(state, action);
    case actionTypes.PAID_POLICIES_SET_FILTER_PARAMS: return setFilterParams(state, action);
    case actionTypes.PAID_POLICIES_SET_IS_EMPTY_FILTER: return setIsEmptyFilters(state, action);
    case actionTypes.PAID_POLICIES_SET_IS_NOT_EMPTY_FILTER: return setIsNotEmptyFilters(state, action);
    case actionTypes.RESET: return resetItems(state, action);
    default:
        return state;
  }
};

export default downloadSummary;
