import * as actionTypes from '../constants/action_types';
import {updateObject} from '../utilities';

const initialState = {
    downloadUrl: null, 
    emailSent: false, 
    groupedPaymentData: null 
};

const setDownloadUrl = (state, action) => {
    const downloadUrl = action.downloadUrl;
    return updateObject(state, {
      downloadUrl: downloadUrl
    });
};

const setEmailSent = (state, action) => {
    return updateObject(state, {
        emailSent: true
    });
}; 

const setGroupedPaymentsData = (state, action) => {
    const groupedPaymentData = action.groupedPaymentData; 
    return updateObject(state, {
      groupedPaymentData: groupedPaymentData
    });
}; 

const groupedPayment = (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.SET_GROUPED_PAYMENT_DOWNLOAD_RECEIPT_URL: 
        return setDownloadUrl(state, action); 
      case actionTypes.SET_GROUPED_PAYMENT_EMAIL_SENT: 
        return setEmailSent(state, action);
      case actionTypes.SET_GROUPED_PAYMENT_DATA: 
        return setGroupedPaymentsData(state, action);
      case actionTypes.RESET_GROUPED_PAYMENT_DATA:
        return initialState;
      default:
          return state;
    }
}

export default groupedPayment;

